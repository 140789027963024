<template>
  <div class="mx-auto max-w-[1024px] pb-12">
    <Quest v-if="row.id" v-loading="loading" :row="row" :is-readonly="true" />
  </div>
</template>
<script>
import { ref, reactive, provide } from 'vue';
import Question from '@/api/Question';
import Quest from '@/modules/quest/Result.vue';
import { useRoute } from 'vue-router';

export default {
  components: { Quest },
  setup() {
    const row = reactive({});
    const loading = ref(false);
    const route = useRoute();
    loading.value = true;

    provide('actions', {
      saveds: [],
      updateSaved: () => {},
      updateConf: () => {},
    });

    Question.preview({ qId: route.params.qId, token: route.query.token })
      .then((res) => {
        Object.assign(row, res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        loading.value = false;
      });

    return { row, loading };
  },
};
</script>
